<template>
    <p v-for="p in wrapValue" :key="p" style="line-height: 1.5" class="render-text whitespace-normal md:whitespace-pre-wrap" :class="(className, customizeClass)">
        {{ p }}
    </p>
</template>

<script setup lang="ts">
let props = withDefaults(defineProps<{ value: string | string[]; className?: string; customizeClass?: string }>(), {
    className: "text",
    customizeClass: "",
});
const wrapValue = computed(() => {
    let { value } = props;
    return Array.isArray(value) ? value : [value];
});
</script>

<style scoped>
.render-text {
    /* line-height: 1.5; */
}
</style>
